// @flow
import api from 'common-docdok/src/api'
import { surveyActions } from 'common-docdok/src/domain/survey/actions'
import { userActions } from 'common-docdok/src/domain/user/actions'
import type { GenType } from 'common-docdok/src/types'
import { change, stopSubmit } from 'redux-form'
import { I18n } from 'react-redux-i18n'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { asyncAction } from 'common-docdok/src/utils/sagaUtils'
import { healthrelationActions } from 'common-docdok/src/domain/healthrelation/actions'
import swal from 'sweetalert'
import moment from 'moment'

import {
  dynamicFormActionTypes,
  type SubmitFormType
} from '../../../../../common/DynamicForm/actions'
import { infoPanelActions } from '../../../../../common/InfoPanel/actions'
import { alertActions } from '../../../../../common/AlertDialog/actions'
import { patientsActions } from '../actions'
import { createPatientButtonActions } from '../../../../../common/CreatePatientButton/actions'
import { resizeImage, toArrayBuffer } from '../../../../../common/Messaging/utils'

import pdfLogo from '../assets/pdfLogo.jpg'

export const SUPPORTED_TYPES = 'image/png,image/jpeg'

const SIZE_10_M = 10485760
const maxSizeForMimeType = (mimeType) => {
  if (mimeType.includes('video')) return 5 * SIZE_10_M
  return SIZE_10_M
}

const prepareFile = (file: File) => {
  if (
    file.type === 'application/pdf' ||
    file.type === 'application/msword' ||
    file.type === 'application/dot' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    const dUrl =
      file.type === 'application/pdf' ? pdfLogo : '/messaging/playVideo.jpeg'
    return toArrayBuffer(file).then((arrayBuffer) => {
      const dataUrl = dUrl
      const size = arrayBuffer.byteLength
      return { arrayBuffer, dataUrl, size }
    })
  }
  return resizeImage(file)
}

export function* updateContactDataGen(payload: Object): GenType {
  const { data } = payload
  const userRef = data.uid
  let user = yield select((state: StoreType) => state.userCache.users[userRef])

  if (!user) {
    const winner = yield asyncAction(
      userActions.loadMissingUsersRequested([userRef])
    )
    if (winner.succeeded) {
      [user] = winner.succeeded.payload
    }

    user = yield select((state: StoreType) => state.userCache.users[userRef])
  }

  const { mobileNumber, email, uid } = user
  let failed = false
  if (mobileNumber !== data.mobileNumber) {
    try {
      yield call(api.updateMobileNumber, uid, data.mobileNumber)
      yield put(userActions.loadUsersRequested([userRef]))
    } catch (error) {
      failed = true
      yield put(stopSubmit(payload.name, error))
    }
  }
  if (!failed && email !== data.email) {
    try {
      yield call(api.updateEmail, uid, data.email)
      yield put(userActions.loadUsersRequested([userRef]))
    } catch (error) {
      failed = true
      yield put(stopSubmit(payload.name, error))
    }
  }
  if (!failed) yield put(stopSubmit(payload.name))
}

export function* sendSurveyGen(payload: Object): GenType {
  const patient = yield select(
    (state: StoreType) => state.selectedPatient && state.healthrelationCache.patients[state.selectedPatient]
  )
  const { userRef } = patient
  const patientUuid = patient.uuid

  const { doSchedule, surveys } = payload.data

  const participitions = surveys.map(survey => ({
    userRef,
    patientUuid,
    surveyId: survey.id,
    scheduling: doSchedule ? survey.defaultScheduling : undefined
  }))

  const winner = yield asyncAction(
    surveyActions.sendSurveysRequested(participitions)
  )

  if (winner.succeeded) {
    yield put(stopSubmit(payload.name))
    yield put(patientsActions.toggleSendSurveyFromPatientDialog())
    yield put(surveyActions.getSurveyDefinitionsByPatientRequested(patientUuid))
    const surveyNames = surveys.map(s => s.name).join(',')
    const userName = `${patient.firstName} ${patient.lastName}`

    yield put(
      infoPanelActions.showMessage('surveys.actions.sendSurvey.success', {
        surveyNames,
        users: [userName]
      })
    )
    const schedulingNames = surveys
      .filter(s => s.defaultScheduling)
      .map(s => s.name)
      .join('')
    if (doSchedule && schedulingNames.length > 0) {
      yield put(
        alertActions.showAlert('surveys.actions.sendSurvey.schedulingHint', {
          surveys: schedulingNames
        })
      )
    }
  } else {
    yield put(stopSubmit(payload.name, winner.failed.payload.error))
  }
}

export function* updatPatientDataGen(payload: Object): GenType {
  const { data } = payload

  // date is UTC
  // 12 Dec 1998 -> 1998-11-30T23:00:00.000Z"
  // TODO fix in the server side!
  if (typeof data.birthdate !== 'string') {
    data.birthdate.setHours(data.birthdate.getHours() + 6)
  }

  const winner = yield asyncAction(
    healthrelationActions.updatePatientRequested(data)
  )
  if (winner.succeeded) {
    // const patient = winner.succeeded.payload
    yield put(stopSubmit(payload.name))
  } else {
    yield put(stopSubmit(payload.name, winner.failed.payload.error))
  }
}

export function* updatePatientActivityData(payload: Object): GenType {
  const { data } = payload

  if (data.seriesId !== undefined && data.seriesId !== null) {
    // means series events, ask for confirmation
    const response = yield swal({
      className: 'custom-swal-alert',
      title: I18n.t('confirmationTitle'),
      text: I18n.t('confirmation'),
      buttons: {
        cancel: I18n.t('oneEventOnly'),
        catch: {
          text: I18n.t('entireSeries'),
          value: true
        }
      },
      icon: 'info'
    })
    if (response) {
      data.toUpdateSeries = true
      if (!data.pattern) {
        swal({
          title: 'Error',
          text: I18n.t('selectRecc'),
          icon: 'error'
        })
        yield put(stopSubmit(payload.name))
        return
      }
    } else {
      data.pattern = null
    }
  }
  const updated = yield asyncAction(
    healthrelationActions.updatePatientActivityRequested(data)
  )
  if (updated.succeeded) {
    // const patient = winner.succeeded.payload
    if (data.cb) {
      const file = data.cb(updated)
      if (file) {
        const { arrayBuffer, size } = yield call(prepareFile, file)
        if (size <= maxSizeForMimeType(file.type)) {
          const url = yield call(api.uploadRequestAttachment, file.name || file.type, updated.succeeded.payload.id)
          yield call(api.uploadFile, url.data.url, file.type, arrayBuffer)
        }
      }
    }
    yield put(stopSubmit(payload.name))
    yield put(createPatientButtonActions.toggleCreateEventDialog())
    if (data.id) {
      yield put(
        infoPanelActions.showMessage('messages.events.eventsUpdated', {})
      )
    } else {
      yield put(infoPanelActions.showMessage('messages.events.eventsSaved', {}))
    }
    yield put(
      healthrelationActions.loadPatientActivitiesRequested({
        patientUuid: data.patientUuid,
        physicianUuid: null,
        distinct: false
      })
    )
    yield put(
      healthrelationActions.loadPatientActivitiesRequested({
        patientUuid: data.isCoach ? null : data.patientUuid,
        physicianUuid: data.isCoach ? data.physicianUuid : null,
        distinct: true
      })
    )
  } else {
    yield put(stopSubmit(payload.name, updated.failed.payload.error))
  }
}

export function* setPatientTargetDataGen(payload: Object): GenType {
  const { data } = payload

  const reqPayload = {
    patientUuid: data.patientUuid,
    id: data.id,
    highestHba1c: data.highestHbA1c,
    lowestHba1c: data.lowestHbA1c,
    lowestDailySteps: data.lowestDailySteps,
    highestDailySteps: data.highestDailySteps,
    lowestBmi: data.lowestBMI,
    highestBmi: data.highestBMI,
    lowestSystolicBp: data.lowestSystolicBloodPressure,
    highestSystolicBp: data.highestSystolicBloodPressure,
    lowestDiastolicBp: data.lowestDiastolicBloodPressure,
    highestDiastolicBp: data.highestDiastolicBloodPressure
  }

  const winner = yield asyncAction(
    healthrelationActions.updatePatientTargetRequested(reqPayload)
  )
  if (winner.succeeded) {
    yield put(stopSubmit(payload.name))
    yield put(patientsActions.toggleSetTargetPatientDialog())
  } else {
    yield put(stopSubmit(payload.name, winner.failed.payload.error))
  }
}

export function* submitFormGen({ payload }: SubmitFormType): GenType {
  if (payload.name === 'sendSurveyFromPatient') {
    yield sendSurveyGen(payload)
  } else if (payload.name === 'contactData') {
    yield updateContactDataGen(payload)
  } else if (payload.name === 'patientData') {
    const payloadToConsider = payload
    if (payloadToConsider && payloadToConsider.data && payloadToConsider.data.birthdate) {
      // formate birthdate for payload
      payloadToConsider.data.birthdate = moment(payload.data.birthdate).format('YYYY-MM-DDTHH:mm:ss.000[Z]')
    }
    yield updatPatientDataGen(payloadToConsider)
  } else if (payload.name === 'editEvent') {
    yield updatePatientActivityData(payload)
  } else if (payload.name === 'targetForm') {
    yield setPatientTargetDataGen(payload)
  }
}

export function* listenFormChange(payload: any): GenType {
  if (
    payload && payload.meta && payload.meta.form === 'editEvent' && payload.meta.field === 'startTime'
  ) {
    yield put(change('editEvent', 'endTime', moment(payload.payload).add(15, 'minutes').format()))
  }
}

export function* listenFormRegisterFormField(payload: any): GenType {
  if (
    payload &&
    payload.meta &&
    payload.meta.form === 'editEvent' &&
    payload.payload &&
    payload.payload.type === 'Field' &&
    payload.payload.name === 'name'
  ) {
    const editEventData = yield select((state: StoreType) => state.form.editEvent || {})
    if (editEventData && editEventData.values) {
      yield put(change('editEvent', 'name', `${editEventData.values.name || ''} `))
    }
  }
}

export default function patientSaga(): Array<GenType> {
  return [
    takeEvery(dynamicFormActionTypes.SUBMIT_FORM, submitFormGen),
    takeEvery(dynamicFormActionTypes.CHANGE_INPUT_FIELD, listenFormChange),
    takeEvery(dynamicFormActionTypes.REGISTER_FORM_FIELD, listenFormRegisterFormField)
  ]
}
